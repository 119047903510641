import React from 'react'
import { graphql } from 'gatsby';
import { Layout, SEO, Spotlight, Testimonial } from '../components';

export const query = graphql`
  query {
    header: file(absolutePath: { regex: "/call-header.jpg/" }) { childImageSharp { fluid(maxHeight: 400, maxWidth: 900, cropFocus: CENTER) { ...GatsbyImageSharpFluid_withWebp }}},
    callUs: file(absolutePath: { regex: "/call-us.svg/" }) { publicURL },
    email: file(absolutePath: { regex: "/email.svg/" }) { publicURL },
  }
`
const structuredData = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "name": "Contact",
  }]
}

const ContactUs = ({data}) => (
  <Layout headerBreakpoint={170}>
    <SEO title="Contact" structuredData={structuredData} />

    <Spotlight img={data.header} />

    <section>
      <h1>Contact Us</h1>

      <h2>Let's Make This Super Easy!</h2>

      <p>
        We don't like the idea of making you fill out long, faceless, boring forms. If you want to get in touch with us, it's really simple!
      </p>
    </section>

    <section variant="grey">
      <h2>Give us a call</h2>

      <img src={data.callUs.publicURL} />

      <p>The good old traditional way! There's nothing better than speaking to someone directly, and we're always on the other end of the phone!</p>

      <h3 style={{fontSize: '32px', marginTop: '40px', fontWeight: '900', color: '#1B2538'}}>07883 392 010</h3>

      <a href="tel:07883392010" className="button primary">Call Us Now</a>
    </section>

    <section>
      <h2>No time for a chat?</h2>

      <img src={data.email.publicURL} />

      <p>
        We understand you may be too busy for a phone call or would prefer to liaise electronically. No problem, just drop us an email and we will get back to you as soon as possible!
      </p>

      <h3 style={{fontSize: '32px', marginTop: '40px', fontWeight: '900', color: '#1B2538'}}>hello@gp-maintenance.co.uk</h3>

      <a href="mailto:hello@gp-maintenance.co.uk" className="button secondary">Email Us Now</a>
    </section>

    <Testimonial />
  </Layout>
)

export default ContactUs
